/// <reference path="../../../typings/jquery/jquery.d.ts"/>
/// <reference path="../../../typings/materialize-css/materialize-css.d.ts"/>

class Account {
    private lv: LifeVine;
    private accountForm;
    private accountPasswordForm;
    private accountProfileImageForm;
    private accountProfileImageIFrame;
    private dependents;
    private locations;

    constructor(lv: LifeVine) {
        this.lv = lv;

        this.accountForm = jQuery('#form_account');
        this.accountPasswordForm = jQuery('#form_account_password');
        this.dependents = jQuery('#account_dependents');
        this.locations = jQuery('#account_locations');
        this.accountProfileImageForm = jQuery('#form_account_profile_img');
        this.accountProfileImageIFrame = jQuery('#iframe_account_profile_img');

        this.accountForm.on('submit', event => {
            event.preventDefault();
            event.stopPropagation();

            this.submitUser();
        });

        this.accountPasswordForm.on('submit', event => {
            event.preventDefault();
            event.stopPropagation();

            this.changePassword();
        });

        this.accountForm.on('reset', event => {
            event.preventDefault();
            event.stopPropagation();

            this.refresh();
        });

        this.dependents.find('#account_add_dependent').click(event => this.addDependent(event));
        this.dependents.on('click', 'a.edit-dependent', event => this.editDependent(event));
        this.dependents.on('click', 'a.delete-dependent', event => this.deleteDependent(event));

        this.locations.find('#account_add_location').click(event => this.addLocation(event));
        this.locations.on('click', 'a.make-primary', event => this.makePrimaryLocation(event));
        this.locations.on('click', 'a.edit-location', event => this.editLocation(event));
        this.locations.on('click', 'a.delete-location', event => this.deleteLocation(event));

        this.accountProfileImageForm.on('submit', event => this.uploadImage(event));
        this.accountProfileImageIFrame.on('load', event => {
            event.target.contentWindow.postMessage('message', lifeVineApiUrl);
        });

        window.addEventListener('message', this.receiveImageData, false);

        this.refresh();
    }

    public refresh() {
        this.lv.account().get()
            .done(user => {
                this.showAccount(user);
            });
    }

    private showAccount(user) {
        setFormData(this.accountForm, user);
        this.accountForm.find('select').material_select();
        Materialize.updateTextFields();
        if (user.user_type === 'client' || user.user_type === 'sitter') {
            if (user.user_type === 'client') {
                this.getDependents();
            }
            this.getLocations();
        }
    }

    private showDependents(dependents) {
        this.dependents.find('.dependent').remove();
        dependents.forEach(dependent => {
            let content = '<div class="col s12 l6 dependent">'
                + `${dependent.first_name} ${dependent.last_name} - ${dependent.dob_display}<br>`
                + `<a href="#" data-dependent-id="${dependent.id}" class="edit-dependent">Edit</a>`
                + ` | <a href="#" data-dependent-id="${dependent.id}" class="delete-dependent">Delete</a>`
                + '</div>';
            this.dependents.append(content);
        });
    }

    private showLocations(locations) {
        this.locations.find('.location').remove();
        locations.forEach(location => {
            let content = '<div class="col s12 l6 location">'
                + location.line1 + '<br>'
                + (location.line2 ? location.line2 + '<br>' : '')
                + location.city + ' ' + location.state + ' ' + location.zip + '<br>'
                + (location.primary ?
                    'Primary' :
                    `<a href="#" data-location-id="${location.id}" class="make-primary">Make primary</a>`) + ' | '
                + `<a href="#" data-location-id="${location.id}" class="edit-location">Edit</a>`
                + (location.primary ?
                    '' :
                    ` | <a href="#" data-location-id="${location.id}" class="delete-location">Delete</a>`)
                + '</div>';
            this.locations.append(content);
        });
    }

    private submitUser() {
        let formData = getFormData(this.accountForm);

        this.lv.account().save(formData)
            .done(data => {
                if (data.success === true) {
                    $('.session-name').text(formData['first_name']);
                    Materialize.toast('Your account has been updated', 3000);
                    this.refresh();
                } else {
                    this.setErrors(data.errors);
                }
            });
    }

    private changePassword() {
        let data = getFormData(this.accountPasswordForm);
        if (data['password'] !== data['confirm']) {
            Materialize.toast('Passwords do not match', 2000);
            return;
        }
        this.lv.account().changePassword(data['current_password'], data['password'])
            .done(() => {
                Materialize.toast('Password changed', 3000);
                this.accountPasswordForm[0].reset();
            })
            .fail(xhr => {
                jQuery.each(xhr.responseJSON.errors, (field, message) => {
                    let $node = this.accountPasswordForm.find(`[name="${field}"]`);
                    let id = $node[0].id;
                    this.accountPasswordForm.find(`[for="${id}"]`).attr('data-error', message);
                    $node.removeClass('valid').addClass('invalid');
                });
                Materialize.toast('Password not changed', 3000);
            });
    }

    private setErrors(errors) {
        jQuery.each(errors, (field, message) => {
            this.accountForm.find(`[for="${field}"]`).attr('data-error', message);
            this.accountForm.find(`#${field}`).removeClass('valid').addClass('invalid');
        });
        // errors.forEach()
        console.log(errors);
    }

    private addDependent(event: Event) {
        event.stopPropagation();
        event.preventDefault();

        new DependentModal(this.lv.account().dependents(), null, () => {
            this.getDependents();
        });
    }

    private editDependent(event: Event) {
        event.stopPropagation();
        event.preventDefault();

        let dependentId = jQuery(event.target).data('dependent-id');
        new DependentModal(this.lv.account().dependents(), dependentId, () => {
            this.getDependents();
        });
    }

    private deleteDependent(event: Event) {
        event.stopPropagation();
        event.preventDefault();

        let dependentId = jQuery(event.target).data('dependent-id');
        this.lv.account().dependents().remove(dependentId)
            .done(() => {
                this.getDependents();
            })
            .fail((xhr) => {
                Materialize.toast(xhr.responseJSON.error, 2000);
            });

    }

    private addLocation(event: Event) {
        event.stopPropagation();
        event.preventDefault();

        new LocationModal(this.lv.account().locations(), null, () => {
            this.getLocations();
        });
    }

    private makePrimaryLocation(event: Event) {
        event.stopPropagation();
        event.preventDefault();

        let locationId = jQuery(event.target).data('location-id');
        this.lv.account().locations().makePrimary(locationId)
            .done(() => {
                Materialize.toast('Primary location updated', 3000);
                this.getLocations();
            });
    }

    private editLocation(event: Event) {
        event.stopPropagation();
        event.preventDefault();

        let locationId = jQuery(event.target).data('location-id');
        new LocationModal(this.lv.account().locations(), locationId, () => {
            this.getLocations();
        });
    }

    private deleteLocation(event: Event) {
        event.stopPropagation();
        event.preventDefault();

        let locationId = jQuery(event.target).data('location-id');
        this.lv.account().locations().remove(locationId)
            .done(() => {
                this.getLocations();
            })
            .fail((xhr) => {
                Materialize.toast(xhr.responseJSON.error, 2000);
            });
    }

    private getDependents() {
        this.lv.account().dependents().get()
            .done(dependents => {
                this.showDependents(dependents);
            });
    }

    private getLocations() {
        this.lv.account().locations().get()
            .done(locations => {
                this.showLocations(locations);
            });
    }

    private uploadImage(event: Event) {
        // event.preventDefault();
        // event.stopPropagation();
        let token = this.lv.session().getToken();
        let url = `${lifeVineApiUrl}/account/upload/${token}`;
        $(event.target)
            .attr('action', url);
    }

    private receiveImageData(event) {
        console.log(event.data);
    }
}

window.app.page('page-account', () => {
    return params => {
        if (window.controllers.account) {
            window.controllers.account.refresh();
        } else {
            window.controllers.account = new Account(window.lifeVine);
        }
    };
});